<template>
  <div>
    <form @submit.prevent="submitUpdate" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações básicas">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-7">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Nome completo
                    </label>
                    <input type="text" v-model="item.full_name" class="form-control"
                      :class="{ 'is-invalid': $v.item.full_name.$error }" />
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      CPF
                    </label>
                    <input v-mask="['###.###.###-##']" type="tel" v-model="item.cpf"
                      :class="{ 'is-invalid': $v.item.cpf.$error }" name="cpf" placeholder="000.000.000-00"
                      class="form-control" />
                  </div>
                </div>

                <div class="col-md-9">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      E-mail
                    </label>
                    <input type="email" v-model="item.email" :class="{ 'is-invalid': $v.item.email.$error }"
                      class="form-control" />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i> 
                      Tipo
                    </label>
                    <v-select label="title" :searchable="false" :class="{ 'is-invalid': $v.item.type_2.$error }" item-text="title" item-value="code" v-model="item.type_2" :options="optionsTypes">
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-tabs pills class="mt-2">
            <b-tab title="Aulas" active>
              <b-card no-body header-class="mb-0">
                <b-table ref="refCartListTable" :items="item.lessons" responsive :fields="fieldsLessons" primary-key="id"
                  show-empty empty-text="Nenhum registro até o momento.">
                  <template #cell(title)="data">
                    {{ data.item.title }}
                  </template>

                  <template #cell(price)="data">
                    {{ data.item.price }}
                  </template>

                  <template #cell(action)="data">
                    <feather-icon :id="`order-${data.item.id}-preview-icon`" icon="EditIcon" size="16"
                      class="cursor-pointer cursor" @click="
                        $router.push({
                          name: 'lessons-edit',
                          params: { uuid: data.item.uuid },
                        })
                        " />
                  </template>
                </b-table>
              </b-card>
            </b-tab>

            <b-tab title="Materiais">
              <b-card no-body header-class="mb-0">
                <b-table :items="item.medias" responsive :fields="fieldsLessons" primary-key="id" show-empty
                  empty-text="Nenhum registro encontrado">
                  <template #cell(title)="data">
                    <a :href="data.item.url" target="_blank" class="d-flex-left">
                      <feather-icon icon="ExternalLinkIcon" />
                      <span class="ml-1">{{ data.item.title }}</span>
                    </a>
                  </template>

                  <template #cell(action)="data">
                    <feather-icon :id="`order-${data.item.id}-preview-icon`" icon="EditIcon" size="16"
                      class="cursor-pointer cursor" @click="
                        $router.push({
                          name: 'medias-edit',
                          params: { uuid: data.item.uuid },
                        })
                        " />
                  </template>
                </b-table>
              </b-card>
            </b-tab>

            <b-tab title="Tags">
              <b-card>
                <b-card-text>
                  <label for="">Tag Tema</label>
                  <v-select multiple label="title" item-text="title" item-value="code" v-model="item.tags"
                    placeholder="Digite o título da tag" :options="optionsTagTema" @search="fetchTagsCategoryTema"
                    :class="{ 'is-invalid': $v.item.tags.$error }">
                    <span slot="no-options" @click="$refs.selectTema.open = false">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </b-card-text>
              </b-card>
            </b-tab>

            <b-tab title="Avaliações">
              <b-card no-body header-class="mb-0">
                <app-collapse v-for="(item, index) in item.evaluates" :key="`collpase-${index}`">
                  <app-collapse-item :title="`<div class='d-flex' style='justify-content: space-between; align-items: center;'> <div>${item.name} - ${item.discipline}</div> <div class='d-flex pr-3' style='justify-content: center; justify-items: center; display: flex; align-items: center; column-gap: 5px;'><i class='bi bi-star-fill' style='color: #00bb76;font-size: 0.7rem;'></i> <span>${item.total.toFixed(2)} (${item.evaluates.length})</span></div></div>`">
                    <b-list-group v-for="(evaluate, i) in item.evaluates" :key="`evalu-${i}`">
                      <b-list-group-item>
                       <div class="form-row justify-content-between">
                        <div class="col-md-7">
                          <div v-if="$can('Editar', 'Clientes')" class="btn-link cursor-pointer" @click="$router.push({name: 'customers-edit', params: { uuid: evaluate.customer_uuid }})">
                            {{ evaluate.customer_name }}
                        </div>
                          <span v-else>
                            {{ evaluate.customer_name }}
                          </span>
                        </div>
                        <div class="col-md-2 text-center">
                          <div class="d-flex" style="column-gap: 4px;justify-content: center; align-items: center;">
                            <i class="bi bi-star-fill" style="color: #00bb76;font-size: 0.7rem;"></i> 
                            <span>{{ evaluate.total.toFixed(2) }}</span>
                          </div>
                        </div>

                        <div class="col-md-2">
                          {{ evaluate.created_at }}
                        </div>

                        <div class="col-md-12" v-if="evaluate.comment">
                          {{ evaluate.comment }}
                        </div>
                       </div>
                      </b-list-group-item>
                    </b-list-group>
                  </app-collapse-item>
                </app-collapse>

                <p class="p-2 mb-0"v-if="!item.evaluates.length">
                  Nenhuma avaliação até o momento.
                </p>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>

        <div class="col-md-4">
          <b-card header="Imagem" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12">
                  <input id="image" type="file" accept="image/webp" class="form-control-file hidden"
                    @change="previewImage" />
                  <div class="form-group mb-0">
                    <label for="image" class="d-block text-center mb-0" style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        cursor: pointer;
                        font-size: 1.1rem;
                      ">
                      <div style="min-height: 172px; line-height: 11" v-if="!preview && !image_url" class="">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img for="image" :src="preview" style="width: auto; height: 171px" fluid />
                      </template>
                      <template v-else-if="!preview && image_url">
                        <b-img for="image" :src="image_url" style="width: auto; height: 171px" fluid />
                      </template>
                    </label>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <div class="card shadow-none border-0 mb-1 rounded-0 card-header-custom-actions">
            <div class="card-header">
              <h4 class="mb-0">
                Conta Bancária
                <small class="d-block">
                  Conta bancária para pagamento.</small>
              </h4>
            </div>

            <div class="card-body">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Banco</label>
                    <v-select label="title" item-text="title" item-value="code" v-model="item.bank" placeholder="Banco"
                      :options="optionsBanks">
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Agência</label>
                    <input type="tel" class="form-control" v-model="item.agency">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Conta</label>
                    <input type="tel" class="form-control" v-model="item.account">
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Tipo da conta</label>
                    <v-select label="title" item-text="title" item-value="code" v-model="item.type"
                      placeholder="Tipo de conta"
                      :options="[{ code: 'c', title: 'Conta corrente' }, { code: 'p', title: 'Conta Poupança' }]">
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Nome do Favorecido</label>
                    <input type="text" class="form-control" v-model="item.favored_name">
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <label for="">CPF do Favorecido</label>
                    <input v-mask="['###.###.###-##']" type="tel" class="form-control" v-model="item.favored_document">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow-none border-0 mb-1 rounded-0 card-header-custom-actions">
            <div class="card-header">
              <h4 class="mb-0">
                Valor da hora
              </h4>
            </div>

            <div class="card-body">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group mb-md-0">
                    <label for="">Ensino Médio</label>
                    <money class="form-control" placeholder="0,00" type="tel" v-bind="money"
                      v-model="item.value_higher" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="">Ensino Superior</label>
                    <money class="form-control" placeholder="0,00" type="tel" v-bind="money" v-model="item.value_lower" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions">
            <div class="card-header">
              <div class="form-row">
                <div class="col-md-9 col-8">
                  <h4 class="mb-0">
                    Telefone(s)
                    <small class="d-block">
                      Cadastre 1 ou mais números.</small>
                  </h4>
                </div>
                <div class="col-md-3 col-4">
                  <button type="button" @click="addPhone" class="btn-block btn btn-primary text-center">
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="d-flex mb-1" v-for="(element, index) in item.phones" :key="index">

                <input type="text" v-mask="['(##) #####-####', '(##) ####-####']" v-model="element.phone"
                  class="form-control">

                <button type="button" class="btn btn-danger ml-1">
                  <i class="bi bi-trash3"></i>
                </button>

              </div>

              <p class="mb-0" v-if="!item.phones.length">
                Nenhum telefone cadastrado.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-row justify-content-end mt-1">
            <div class="col-md-2">
              <button class="btn btn-block btn-success" @click="confirmUpdate" type="button">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BTabs,
  BImg,
  BTab,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BEmbed,
  BLink,
  BAlert,
  BTable,
  BListGroup,
  BListGroupItem,
  // BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import { Money } from "v-money";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BImg,
    BEmbed,
    BTabs,
    BTab,
    // BModal,
    BCardText,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BLink,
    BAlert,
    quillEditor,
    ButtonsActionsFooter,
    BTable,
    Money,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      uuid: null,
      submited: false,
      preview: null,
      image_url: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        full_name: "",
        cpf: "",
        phones: [],
        email: "",
        notes: "",
        lessons: [],
        tags: [],
        image: "",
        bank: "",
        agency: "",
        account: "",
        type: "c",
        favored_name: "",
        favored_document: "",
        value_higher: "",
        value_lower: "",
        type_2: "",
        evaluates: [],
      },
      optionsTypes: [
      {
          title: 'RPA',
          code: 'rpa',
        },
        {
          title: 'MEI',
          code: 'mei',
        },
      ],
      fieldsLessons: [
        {
          key: "title",
          label: "Título",
          sortable: false,
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      optionsTagTema: [],
      optionsBanks: [],
    };
  },
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
      },
      email: {
        required,
        email
      },
      type_2: {
        required,
      },
      tags: {
        required,
      },
    },
  },
  async mounted() {
    this.uuid = this.$route.params.uuid;
    this.getData();

    await this.$store.dispatch("Bank/forSelect").then((res) => {
      this.optionsBanks = res
    })
  },
  computed: {
    ...mapState("Teacher", ["teacher"]),
  },
  methods: {
    addPhone() {
      this.item.phones.push({
        phone: ''
      })
    },
    confirmUpdate() {
      this.$swal({
        title: "Tem certeza?",
        text: "Caso você tenha alterado alguma informação, os dados do professor serão alterados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.submitUpdate()
        }
      });
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", { category_id: 1, term: term })
        console.log(this.optionsTagTema)
      }
    },
    async getData() {
      this.$store.dispatch("Teacher/show", this.$route.params.uuid).then(() => {
        if (this.teacher) {
          this.item = this.teacher;
          this.item.phones = this.item.phones ?? []
          this.image_url = this.item.image_url;

          if (this.item.type_2) {
            this.item.type_2 = {
              title: this.item.type_2 === 'rpa' ? 'RPA' : 'MEI',
              code: this.item.type_2
            }
          }
        }
      });
    },
    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append("image", this.item.image ?? '');
      formData.append("full_name", this.item.full_name);
      formData.append("cpf", this.item.cpf);
      formData.append("email", this.item.email);
      formData.append("phones", JSON.stringify(this.item.phones));

      formData.append("type_2", this.item.type_2.code);
      formData.append("bank", this.item.bank ? this.item.bank.code : '');
      formData.append("agency", this.item.agency ?? '');
      formData.append("account", this.item.account ?? '');
      formData.append("type", this.item.type ? this.item.type.code : '');
      formData.append("favored_name", this.item.favored_name ?? '');
      formData.append("favored_document", this.item.favored_document ?? '');

      formData.append("value_higher", this.item.value_higher ?? '');
      formData.append("value_lower", this.item.value_lower ?? '');

      formData.append("tags", JSON.stringify(this.item.tags));

      if (!this.$v.item.$error) {
        this.$store
          .dispatch("Teacher/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.$router.push({ name: "teachers-list" });
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
};
</script>

<style>
.beforeUpload .icon {
  width: 50px !important;
}
</style>
