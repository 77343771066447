var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { enctype: "multipart/form-data" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitUpdate.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "col-md-8" },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-1",
                  attrs: { header: "Informações básicas" },
                },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-7" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Nome completo "),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.full_name,
                                expression: "item.full_name",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.item.full_name.$error,
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.full_name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "full_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" CPF "),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##"],
                                expression: "['###.###.###-##']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.cpf,
                                expression: "item.cpf",
                              },
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.cpf.$error },
                            attrs: {
                              type: "tel",
                              name: "cpf",
                              placeholder: "000.000.000-00",
                            },
                            domProps: { value: _vm.item.cpf },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.item, "cpf", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" E-mail "),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.email,
                                expression: "item.email",
                              },
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.email.$error },
                            attrs: { type: "email" },
                            domProps: { value: _vm.item.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.item, "email", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group mb-md-0" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Tipo "),
                            ]),
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.item.type_2.$error,
                                },
                                attrs: {
                                  label: "title",
                                  searchable: false,
                                  "item-text": "title",
                                  "item-value": "code",
                                  options: _vm.optionsTypes,
                                },
                                model: {
                                  value: _vm.item.type_2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "type_2", $$v)
                                  },
                                  expression: "item.type_2",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-tabs",
                { staticClass: "mt-2", attrs: { pills: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Aulas", active: "" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "", "header-class": "mb-0" } },
                        [
                          _c("b-table", {
                            ref: "refCartListTable",
                            attrs: {
                              items: _vm.item.lessons,
                              responsive: "",
                              fields: _vm.fieldsLessons,
                              "primary-key": "id",
                              "show-empty": "",
                              "empty-text": "Nenhum registro até o momento.",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _vm._v(" " + _vm._s(data.item.title) + " "),
                                  ]
                                },
                              },
                              {
                                key: "cell(price)",
                                fn: function (data) {
                                  return [
                                    _vm._v(" " + _vm._s(data.item.price) + " "),
                                  ]
                                },
                              },
                              {
                                key: "cell(action)",
                                fn: function (data) {
                                  return [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor",
                                      attrs: {
                                        id:
                                          "order-" +
                                          data.item.id +
                                          "-preview-icon",
                                        icon: "EditIcon",
                                        size: "16",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "lessons-edit",
                                            params: { uuid: data.item.uuid },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Materiais" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "", "header-class": "mb-0" } },
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.item.medias,
                              responsive: "",
                              fields: _vm.fieldsLessons,
                              "primary-key": "id",
                              "show-empty": "",
                              "empty-text": "Nenhum registro encontrado",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "d-flex-left",
                                        attrs: {
                                          href: data.item.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "ExternalLinkIcon" },
                                        }),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(data.item.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(action)",
                                fn: function (data) {
                                  return [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor",
                                      attrs: {
                                        id:
                                          "order-" +
                                          data.item.id +
                                          "-preview-icon",
                                        icon: "EditIcon",
                                        size: "16",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "medias-edit",
                                            params: { uuid: data.item.uuid },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Tags" } },
                    [
                      _c(
                        "b-card",
                        [
                          _c(
                            "b-card-text",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Tag Tema"),
                              ]),
                              _c(
                                "v-select",
                                {
                                  class: {
                                    "is-invalid": _vm.$v.item.tags.$error,
                                  },
                                  attrs: {
                                    multiple: "",
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título da tag",
                                    options: _vm.optionsTagTema,
                                  },
                                  on: { search: _vm.fetchTagsCategoryTema },
                                  model: {
                                    value: _vm.item.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "tags", $$v)
                                    },
                                    expression: "item.tags",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectTema.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Avaliações" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "", "header-class": "mb-0" } },
                        [
                          _vm._l(_vm.item.evaluates, function (item, index) {
                            return _c(
                              "app-collapse",
                              { key: "collpase-" + index },
                              [
                                _c(
                                  "app-collapse-item",
                                  {
                                    attrs: {
                                      title:
                                        "<div class='d-flex' style='justify-content: space-between; align-items: center;'> <div>" +
                                        item.name +
                                        " - " +
                                        item.discipline +
                                        "</div> <div class='d-flex pr-3' style='justify-content: center; justify-items: center; display: flex; align-items: center; column-gap: 5px;'><i class='bi bi-star-fill' style='color: #00bb76;font-size: 0.7rem;'></i> <span>" +
                                        item.total.toFixed(2) +
                                        " (" +
                                        item.evaluates.length +
                                        ")</span></div></div>",
                                    },
                                  },
                                  _vm._l(
                                    item.evaluates,
                                    function (evaluate, i) {
                                      return _c(
                                        "b-list-group",
                                        { key: "evalu-" + i },
                                        [
                                          _c("b-list-group-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _vm.$can(
                                                      "Editar",
                                                      "Clientes"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "btn-link cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$router.push(
                                                                  {
                                                                    name: "customers-edit",
                                                                    params: {
                                                                      uuid: evaluate.customer_uuid,
                                                                    },
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  evaluate.customer_name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                evaluate.customer_name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-2 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-flex",
                                                        staticStyle: {
                                                          "column-gap": "4px",
                                                          "justify-content":
                                                            "center",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-star-fill",
                                                          staticStyle: {
                                                            color: "#00bb76",
                                                            "font-size":
                                                              "0.7rem",
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              evaluate.total.toFixed(
                                                                2
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-2" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          evaluate.created_at
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                evaluate.comment
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-12",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              evaluate.comment
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          !_vm.item.evaluates.length
                            ? _c("p", { staticClass: "p-2 mb-0" }, [
                                _vm._v(" Nenhuma avaliação até o momento. "),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Imagem" } },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("input", {
                          staticClass: "form-control-file hidden",
                          attrs: {
                            id: "image",
                            type: "file",
                            accept: "image/webp",
                          },
                          on: { change: _vm.previewImage },
                        }),
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c(
                            "label",
                            {
                              staticClass: "d-block text-center mb-0",
                              staticStyle: {
                                border: "1px solid rgb(222 222 222)",
                                "border-radius": "4px",
                                "border-style": "dashed",
                                cursor: "pointer",
                                "font-size": "1.1rem",
                              },
                              attrs: { for: "image" },
                            },
                            [
                              !_vm.preview && !_vm.image_url
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "min-height": "172px",
                                        "line-height": "11",
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          size: "19",
                                          icon: "ImageIcon",
                                        },
                                      }),
                                      _vm._v(" Selecionar imagem "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.preview
                                ? [
                                    _c("b-img", {
                                      staticStyle: {
                                        width: "auto",
                                        height: "171px",
                                      },
                                      attrs: {
                                        for: "image",
                                        src: _vm.preview,
                                        fluid: "",
                                      },
                                    }),
                                  ]
                                : !_vm.preview && _vm.image_url
                                ? [
                                    _c("b-img", {
                                      staticStyle: {
                                        width: "auto",
                                        height: "171px",
                                      },
                                      attrs: {
                                        for: "image",
                                        src: _vm.image_url,
                                        fluid: "",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-1 rounded-0 card-header-custom-actions",
                },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Banco"),
                            ]),
                            _c(
                              "v-select",
                              {
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Banco",
                                  options: _vm.optionsBanks,
                                },
                                model: {
                                  value: _vm.item.bank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "bank", $$v)
                                  },
                                  expression: "item.bank",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Agência"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.agency,
                                expression: "item.agency",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.item.agency },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "agency",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Conta"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.account,
                                expression: "item.account",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.item.account },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "account",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tipo da conta"),
                            ]),
                            _c(
                              "v-select",
                              {
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Tipo de conta",
                                  options: [
                                    { code: "c", title: "Conta corrente" },
                                    { code: "p", title: "Conta Poupança" },
                                  ],
                                },
                                model: {
                                  value: _vm.item.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "type", $$v)
                                  },
                                  expression: "item.type",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Nome do Favorecido"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.favored_name,
                                expression: "item.favored_name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.favored_name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "favored_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("CPF do Favorecido"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##"],
                                expression: "['###.###.###-##']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.favored_document,
                                expression: "item.favored_document",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel" },
                            domProps: { value: _vm.item.favored_document },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "favored_document",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-1 rounded-0 card-header-custom-actions",
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group mb-md-0" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Ensino Médio"),
                            ]),
                            _c(
                              "money",
                              _vm._b(
                                {
                                  staticClass: "form-control",
                                  attrs: { placeholder: "0,00", type: "tel" },
                                  model: {
                                    value: _vm.item.value_higher,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "value_higher", $$v)
                                    },
                                    expression: "item.value_higher",
                                  },
                                },
                                "money",
                                _vm.money,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Ensino Superior"),
                            ]),
                            _c(
                              "money",
                              _vm._b(
                                {
                                  staticClass: "form-control",
                                  attrs: { placeholder: "0,00", type: "tel" },
                                  model: {
                                    value: _vm.item.value_lower,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "value_lower", $$v)
                                    },
                                    expression: "item.value_lower",
                                  },
                                },
                                "money",
                                _vm.money,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions",
                },
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-md-3 col-4" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-block btn btn-primary text-center",
                            attrs: { type: "button" },
                            on: { click: _vm.addPhone },
                          },
                          [_c("i", { staticClass: "bi bi-plus" })]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm._l(_vm.item.phones, function (element, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "d-flex mb-1" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["(##) #####-####", "(##) ####-####"],
                                  expression:
                                    "['(##) #####-####', '(##) ####-####']",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: element.phone,
                                  expression: "element.phone",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: element.phone },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    element,
                                    "phone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._m(3, true),
                          ]
                        )
                      }),
                      !_vm.item.phones.length
                        ? _c("p", { staticClass: "mb-0" }, [
                            _vm._v(" Nenhum telefone cadastrado. "),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "form-row justify-content-end mt-1" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success",
                    attrs: { type: "button" },
                    on: { click: _vm.confirmUpdate },
                  },
                  [_vm._v(" Salvar ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Conta Bancária "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(" Conta bancária para pagamento."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v(" Valor da hora ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-9 col-8" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Telefone(s) "),
        _c("small", { staticClass: "d-block" }, [
          _vm._v(" Cadastre 1 ou mais números."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "btn btn-danger ml-1", attrs: { type: "button" } },
      [_c("i", { staticClass: "bi bi-trash3" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }